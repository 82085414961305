import { AxiosRequestConfig, AxiosResponse } from 'axios';
import createHttp from '@/utils/axiosHttp';
import { isResponse } from '@/types';
import NProgress from 'nprogress';
import { message } from 'ant-design-vue';

const http = createHttp({
  timeout: 10000,
  baseURL: 'api',
  withCredentials: true,
  xsrfCookieName: 'Authorization',
  xsrfHeaderName: 'Authorization',
});

const isAxiosResponse = (obj: any): obj is AxiosResponse => {
  return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};

// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  return req;
});

// 解析响应结果
http.interceptors.response.use(
  
  (rep: AxiosResponse) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    const { data } = rep;
    if (isResponse(data)) {
      return data.code === 0 ? data : data;
    }
    // 如果返回code为0也进入错误处理
    if (data.code === 0) {
      
      return Promise.reject({
        message: data.msg,
        code: data.code,
        data: '',
      });
    }
    return { message: rep.statusText, code: rep.status, data:data.data };
  },
  (error) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    message.error(error.response.statusText);
    if (error.response && isAxiosResponse(error.response)) {
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject(error);
  }
);


export default http;
